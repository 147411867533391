import { BigNumber } from "ethers";
import { CHAIN_CODE } from "~/@config/chain-code";
import { EVM_CHAIN_LIST } from "~/@config/chain-list";
import ImgUSDB from "~/assets/images/usdb.png";
import ImgUSDC from "~/assets/images/usdc.png";
import ImgUSDT from "~/assets/images/usdt.png";
import { parseAmountToken } from "./block-chain.helper";


const MUL_DIR = 100;

export const calculateSwapRate = ({ amountIn = parseAmountToken(1), tokensForPresale, totalRaised }: { amountIn: BigNumber, tokensForPresale: BigNumber, totalRaised: BigNumber }) => {
  if (totalRaised.eq(0) || amountIn.eq(0)) {
    return parseAmountToken(0)
  }
  return amountIn.mul(tokensForPresale).div(totalRaised);
}


export const calculateAmountApprove = ({ tokensForPresale, percentForLiquidity, percentFeeRaised }: { tokensForPresale: number, percentForLiquidity: number, percentFeeRaised: number }) => {

  const ONE_HUNDRED_PERCENT = 100 * MUL_DIR;
  const tokensForLiquidity = parseAmountToken(tokensForPresale).mul(percentForLiquidity * MUL_DIR).mul(ONE_HUNDRED_PERCENT - percentFeeRaised * MUL_DIR)
    .div(ONE_HUNDRED_PERCENT).div(ONE_HUNDRED_PERCENT);

  return tokensForLiquidity.add(parseAmountToken(tokensForPresale));

}

export const calculateTokensForLiquidity = ({ tokensForPresale, percentForLiquidity, percentFeeRaised }: { tokensForPresale: number, percentForLiquidity: number, percentFeeRaised: number }) => {

  const ONE_HUNDRED_PERCENT = 100 * MUL_DIR;
  const tokensForLiquidity = parseAmountToken(tokensForPresale).mul(percentForLiquidity * MUL_DIR).mul(ONE_HUNDRED_PERCENT - percentFeeRaised * MUL_DIR)
    .div(ONE_HUNDRED_PERCENT).div(ONE_HUNDRED_PERCENT);

  return tokensForLiquidity;

}

export const wlAccounts = [
  "0x4A499535998e6CeAbDbcd3792B92737B9d41b59A".toLowerCase(),
  "0x68a44D9306084DDBc7b53142A218cc9cAb795c63".toLowerCase(),
  "0xb49dBfEF796737F777B70D2C5201341Ee61d31Ef".toLowerCase(),
  "0x934B04d4B0980cbd3Ba34A60b597Ed6b64605920".toLowerCase(),
  "0x60Cf1b129233172a673957EDbD176FA03252153A".toLowerCase(),
  "0x458dd416330ACda01424fbBc5B1453D0881C9A6a".toLowerCase(),
  "0x07fd8d5418fecEbC36F4168aD77A8DdFf2e1520D".toLowerCase(),
  "0xd1c29F8d87b6b881dF4CD0F7C02F59ED1692f02E".toLowerCase(),
  "0xDe54B4ebc9c9B376853E016086bb0FD8fE41bdED".toLowerCase(),
]

export const EVM_SSO = [
  EVM_CHAIN_LIST[CHAIN_CODE.BSC_MAINNET],
  // EVM_CHAIN_LIST[CHAIN_CODE.POLYGON_MAINNET],
  // EVM_CHAIN_LIST[CHAIN_CODE.VICTION_MAINNET],
  EVM_CHAIN_LIST[CHAIN_CODE.OP_MAINNET],
  EVM_CHAIN_LIST[CHAIN_CODE.AVAX],
  EVM_CHAIN_LIST[CHAIN_CODE.BASE_MAINNET],
  EVM_CHAIN_LIST[CHAIN_CODE.BLAST_MAINNET],
  EVM_CHAIN_LIST[CHAIN_CODE.MODE_MAINNET],
  EVM_CHAIN_LIST[CHAIN_CODE.ARBITRUM_MAINNET],
]

export const EVM_AGG = [
  EVM_CHAIN_LIST[CHAIN_CODE.BSC_MAINNET],
  EVM_CHAIN_LIST[CHAIN_CODE.VICTION_MAINNET],
  EVM_CHAIN_LIST[CHAIN_CODE.OP_MAINNET],
  EVM_CHAIN_LIST[CHAIN_CODE.BASE_MAINNET],
  // EVM_CHAIN_LIST[CHAIN_CODE.MODE_MAINNET],
]

export const EVM_TESTNETS = [
  CHAIN_CODE.BSC_TESTNET,
  CHAIN_CODE.BLAST_TESTNET,
  CHAIN_CODE.ZETACHAIN_TESTNET,
]

export const getRandomColor = () => {
  var letters = '0123456789ABCDEF';
  var color = '#';
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

export const IconSSO = (chainId: number) => {
  if (chainId === CHAIN_CODE.BASE_MAINNET) {
    return {
      icon: ImgUSDC,
      text: "USDC"
    };
  }
  if (chainId === CHAIN_CODE.BLAST_MAINNET) {
    return {
      icon: ImgUSDB,
      text: "USDB"
    };
  }
  return {
    icon: ImgUSDT,
    text: "USDT"
  };
}
