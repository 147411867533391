import { CopyIcon } from "@chakra-ui/icons";
import {
  Box,
  Card,
  Divider,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  HStack,
  Heading,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Text,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import { FC, useCallback, useRef, useState } from "react";
import { TiPencil } from "react-icons/ti";
import { useNavigate } from "react-router-dom";
import { EVM_CHAIN_LIST } from "~/@config/chain-list";
import NoImg from "~/assets/images/no_img.png";
import { wlAccounts } from "~/common/code.helper";
import { ECollectionType } from "~/common/enums/ECollectionType";
import { getBaseUrl } from "~/common/utils/common.utils";
import PrimaryButton from "~/components/PrimaryButton";
import SecondaryButton from "~/components/SecondaryButton";
import { NftCollectionDto } from "~/dto/nft-project.dto";
import { useConnectWallet } from "~/hooks/@global/useConnectWallet";
import useCopyToClipboard from "~/hooks/@global/useCopyToClipboard";
import { MAIN_ROUTERS, MANAGER_ROUTERS } from "~/routes/routes";
import { ModalUpdateMintConfig } from "./ModalUpdateMintConfig";
import { usePromotion } from "./hooks/usePromotion";

interface INftCollectionCardProps extends NftCollectionDto {}

const NftCollectionCard: FC<INftCollectionCardProps> = (
  props: INftCollectionCardProps
) => {
  const {
    name,
    image,
    address,
    chainId,
    desc,
    symbol,
    endTime,
    exactPrice,
    price,
    versionFactory,
    campaignId,
    type,
    qtyMinted,
    maxTotalSupply,
  } = props;
  const [, copy] = useCopyToClipboard();
  const videoRef = useRef(null);
  const navigate = useNavigate();
  const [openCampaign, setOpenCampaign] = useState(false);
  const { account } = useConnectWallet();

  const [openMintConfig, setOpenMintConfig] = useState(false);

  const { logo: chainLogo, chainName = "" } = EVM_CHAIN_LIST[chainId] || {
    logo: "",
    chainName: "",
  };

  const link = `${getBaseUrl()}${
    MAIN_ROUTERS.NFT_COLLECTION
  }/${chainId}/${address}`;

  // const { qtyMintedCollection, maxTotalSupply } = useMint({
  //   address,
  //   versionFactory,
  // });

  const [promotionData, setPromotionData] = useState({
    qtys: "",
    percents: "",
  });

  const { isOpen, onOpen, onClose } = useDisclosure();

  const { isLoading, update } = usePromotion();

  const updatePromotion = useCallback(() => {
    const { percents, qtys } = promotionData;

    update(
      {
        address,
        qtys: (JSON.parse(qtys) as any[]).map((v) => Number(v)),
        percents: (JSON.parse(percents) as any[]).map((v) => Number(v) * 100),
      },
      onClose
    );
  }, [address, onClose, promotionData, update]);

  return (
    <>
      <Card p="5px">
        <Grid
          w="full"
          p={{ base: "0", lg: "1px 10px  1px" }}
          gap={2}
          templateColumns="repeat(12, 1fr)"
        >
          <GridItem w="full" colSpan={{ base: 12, xl: 1 }} alignSelf={"center"}>
            {image.includes(".mp4") ? (
              <Box h="80px" w="100%">
                <video
                  // loop
                  ref={videoRef}
                  style={{
                    borderRadius: "6px",
                    width: "100%",
                    height: "80px",
                    objectFit: "cover",
                  }}
                  //@ts-ignore
                  autoPlay={true}
                  //@ts-ignore
                  playsInline="playsInline"
                  //@ts-ignore
                  muted="muted"
                  src={image}
                  poster={NoImg}
                ></video>
              </Box>
            ) : (
              <Image
                h={"80px"}
                maxH="80px"
                overflow="hidden"
                w={"full"}
                src={image}
                alt={`logo_${name}`}
                objectFit={"cover"}
                _hover={{
                  transform: "scale(1.05)",
                }}
                borderRadius="6px"
                fallbackSrc={NoImg}
              />
            )}
          </GridItem>

          <GridItem w="full" colSpan={{ base: 12, xl: 2 }} alignSelf={"center"}>
            <VStack>
              <Heading size="xs" fontWeight={700} noOfLines={1}>
                {name}
              </Heading>
            </VStack>
          </GridItem>

          <GridItem w="full" colSpan={{ base: 12, xl: 2 }} alignSelf={"center"}>
            <VStack w="full" alignItems={"start"}>
              {/* {type === ECollectionType.CRAWL &&
                <HStack
                  cursor={{ lg: "pointer" }}
                  onClick={() => { navigate(`${MANAGER_ROUTERS.AGGREGATOR}`) }}
                >
                  <Text fontSize="13px" fontWeight={700}>NFT Aggregator</Text>
                  <Image src={IcCrawl} w="16px" h="16px" />
                </HStack>
              } */}

              <HStack>
                <Text fontSize="xs">Chain: {chainName}</Text>
                <Image src={chainLogo} w="14px" h="14px" />
              </HStack>

              <HStack>
                <Text fontSize="xs">Minted</Text>
                <Text color="blue.600" fontSize="xs" fontWeight={700}>
                  {qtyMinted}/{`${!maxTotalSupply ? `∞` : maxTotalSupply}`}
                </Text>
              </HStack>

              <HStack>
                <Text fontSize="xs">End Time</Text>
                <Text color="blue.600" fontSize="xs" fontWeight={700}>
                  {dayjs(endTime).format("YYYY/MM/DD HH:mm")}
                </Text>
              </HStack>
            </VStack>
          </GridItem>

          <GridItem w="full" colSpan={{ base: 12, xl: 7 }} alignSelf={"center"}>
            <SimpleGrid
              w={"full"}
              spacing={2}
              columns={{
                base: 1,
                md: 2,
                xl: 4,
              }}
            >
              {account && wlAccounts.includes(account.toLowerCase()) && (
                <>
                  {campaignId ? (
                    <>
                      <PrimaryButton
                        fontSize={"10px"}
                        onClick={() => {
                          navigate(
                            `${MANAGER_ROUTERS.MANAGER_CAMPAIGN}/${campaignId}`
                          );
                        }}
                      >
                        Edit Social Engagement
                      </PrimaryButton>
                      {/* <SecondaryButton
                        fontSize={"13px"}
                        onClick={() => {

                        }}
                        color={"red"}
                      >
                        Finalize Campaign
                      </SecondaryButton> */}
                    </>
                  ) : (
                    <PrimaryButton
                      fontSize={"10px"}
                      onClick={() => {
                        navigate(
                          `${MANAGER_ROUTERS.CREATE_CAMPAIGN}/${chainId}/${address}`
                        );
                      }}
                    >
                      Set Up Social Engagement
                    </PrimaryButton>
                  )}
                </>
              )}

              {type === ECollectionType.CRAWL && (
                <PrimaryButton
                  fontSize={"10px"}
                  onClick={() => {
                    setOpenMintConfig(true);
                  }}
                >
                  Update Mint Config
                </PrimaryButton>
              )}

              <SecondaryButton
                w={"full"}
                fontSize={"10px"}
                colorScheme="linkedin"
                onClick={() => {
                  navigate(
                    `${MANAGER_ROUTERS.EDIT_COLLECTION}/${chainId}/${address}`
                  );
                }}
                leftIcon={<TiPencil />}
              >
                Edit
              </SecondaryButton>

              <SecondaryButton
                w={"full"}
                fontSize={"10px"}
                colorScheme="linkedin"
                onClick={() => {
                  navigate(
                    `${MANAGER_ROUTERS.EDIT_COLLECTION_ON_CHAIN}/${chainId}/${address}`
                  );
                }}
                leftIcon={<TiPencil />}
              >
                Edit OnChain
              </SecondaryButton>

              <SecondaryButton
                w={"100%"}
                fontSize={"10px"}
                leftIcon={<CopyIcon />}
                onClick={() => copy(link)}
              >
                Copy link
              </SecondaryButton>

              <SecondaryButton
                fontSize={"10px"}
                onClick={() => window.open(link)}
              >
                View collection
              </SecondaryButton>
            </SimpleGrid>

            {/* <Button w={"100%"}
              colorScheme="cyan"
              onClick={onOpen}
            >
              Setup Promotion
            </Button> */}
          </GridItem>
        </Grid>
      </Card>

      <Modal
        isCentered
        onClose={onClose}
        isOpen={isOpen}
        motionPreset="slideInBottom"
      >
        <ModalOverlay />
        <ModalContent maxWidth="642px" w="100%" borderRadius="12px" mx="15px">
          <ModalHeader bg="#FFFFFF" borderRadius="12px" />
          <ModalCloseButton bg="#FFF" />
          <ModalBody bg="#FFFFFF" px="15px" borderRadius="12px">
            <FormControl mb="3">
              <FormLabel>promotioQtys</FormLabel>
              <Input
                name="qtys"
                value={promotionData.qtys}
                placeholder="qtys"
                onChange={(e) =>
                  setPromotionData({
                    ...promotionData,
                    qtys: e.target.value,
                  })
                }
                onBlur={(e) => {}}
                borderColor="#448AFF !important"
                borderWidth="1px"
                borderStyle="solid"
                borderRadius="10px"
                textAlign="left"
                fontWeight="500"
                fontSize="16px"
                lineHeight="20px"
                bg="#F7F9FA"
                disabled={false}
              />
            </FormControl>
            <FormControl mb="3">
              <FormLabel>promotionPercent</FormLabel>
              <Input
                name="percents"
                value={promotionData.percents}
                placeholder="percents"
                onChange={(e) =>
                  setPromotionData({
                    ...promotionData,
                    percents: e.target.value,
                  })
                }
                onBlur={(e) => {}}
                borderColor="#448AFF !important"
                borderWidth="1px"
                borderStyle="solid"
                borderRadius="10px"
                textAlign="left"
                fontWeight="500"
                fontSize="16px"
                lineHeight="20px"
                bg="#F7F9FA"
                disabled={false}
              />
            </FormControl>
            <Divider mt="20px" mb="20px" />
          </ModalBody>
          <ModalFooter>
            <PrimaryButton variant="ghost" onClick={updatePromotion}>
              Update
            </PrimaryButton>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <ModalUpdateMintConfig
        isOpen={openMintConfig}
        onClose={() => {
          setOpenMintConfig(false);
        }}
        address={address}
        chainId={chainId}
        name={name}
      />
    </>
  );
};
export default NftCollectionCard;
